import {Center, Spinner} from '@chakra-ui/react';
import {useCurrentAssetOverview} from '../../../hooks/current-asset-overview';
import {MarketplaceOpenFooter} from './marketplace-open-footer';
import {OfferingOpenFooter} from './offering-open-footer';
import {GeneralOfferingFooter} from './general-offering-footer';
import {PromotionTimerFooter} from './promotion-timer-footer';

interface FooterControllerProps {
  assetID: string;
  color: string;
  purchaseUrl: string;
  productUrl: string;
  hidePurchaseInfo?: boolean;
}

export const FooterController: React.FC<FooterControllerProps> = ({
  assetID,
  color,
  purchaseUrl,
  productUrl,
  hidePurchaseInfo,
}) => {
  const {
    loading,
    status,
    totalActiveListings,
    lowestPrice,
    marketplaceLastSoldPrice,
    totalAssetPiecesSold,
    totalAssetPieces,
    endDate,
    priceString,
    pricing,
    totalPiecesFromCurrentAssetOffering,
    soldPiecesFromCurrentAssetOffering,
  } = useCurrentAssetOverview(assetID);

  if (loading) {
    return (
      <Center textAlign="center" py={8}>
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="gray.200"
          color={color}
        />
      </Center>
    );
  }

  switch (status) {
    case 'COMING_SOON':
      return (
        <GeneralOfferingFooter
          url={productUrl}
          btnTitle="Learn more"
          title="Coming Soon: Find out more by clicking below"
          status={status}
        />
      );
    case 'OFFERING_OPEN':
      return (
        <OfferingOpenFooter
          offeringPiecesSold={soldPiecesFromCurrentAssetOffering || 0}
          offeringPiecesTotal={totalPiecesFromCurrentAssetOffering || 0}
          color={color}
          url={purchaseUrl}
          currentPrice={priceString}
          piecesAvailable={totalAssetPieces || 0}
          piecesSold={totalAssetPiecesSold || 0}
          currencySymbol={pricing?.symbol || ''}
          currencyPostFix={pricing?.postFix || ''}
          hidePurchaseInfo={hidePurchaseInfo}
          assetId={assetID}
        />
      );
    case 'SOLD_OUT':
      return (
        <GeneralOfferingFooter
          url={purchaseUrl}
          btnTitle="When can I buy more pieces?"
          title="All pieces have now been collected!"
          status={status}
        />
      );
    case 'OFFERING_END':
      return (
        <PromotionTimerFooter
          color={color}
          url={purchaseUrl}
          currentPrice={priceString}
          piecesAvailable={totalAssetPieces || 0}
          endDate={endDate}
          currencySymbol={pricing?.symbol || ''}
          currencyPostFix={pricing?.postFix || ''}
        />
      );
    case 'OFFERING_CLOSED':
    case 'OFFERING_PENDING':
      return (
        <GeneralOfferingFooter
          url={purchaseUrl}
          btnTitle="When can I buy more pieces?"
          title="The initial offering of this Showpiece has finished"
          status={status}
        />
      );
    case 'MARKETPLACE':
      return (
        <MarketplaceOpenFooter
          color={color}
          url={purchaseUrl}
          piecesAvailable={totalActiveListings || 0}
          currentPrice={lowestPrice?.toString() || ''}
          lastSoldPrice={marketplaceLastSoldPrice || 0}
          basePrice={priceString}
          currencySymbol={pricing?.symbol || ''}
          currencyPostFix={pricing?.postFix || ''}
        />
      );
    default:
      return <></>;
  }
};
