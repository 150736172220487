import {Box, Flex, Text} from '@chakra-ui/react';
import {PurchaseButtonWithPrice} from '../components/purchase-button-with-price';
import {useCountdownTimer} from '../../../hooks/countdown-timer';

interface PromotionTimerFooterProps {
  color: string;
  url: string;
  piecesAvailable: number;
  currentPrice: string;
  endDate: number | Date;
  currencySymbol: string;
  currencyPostFix: string;
}

export const PromotionTimerFooter: React.FC<PromotionTimerFooterProps> = ({
  color,
  url,
  currentPrice,
  piecesAvailable,
  endDate,
  currencySymbol,
  currencyPostFix,
}) => {
  const {abbreviatedTimeDifference} = useCountdownTimer(endDate.toString());
  return (
    <Box py={6}>
      <Flex
        bgColor={color}
        align="center"
        justify="space-between"
        color="white"
        px="24px"
        height="60px"
        mb="20px"
        mt="-8px"
      >
        <Text textStyle="h7" fontSize={{base: '15px', xl: '16px'}}>
          Sale of this item closes in:
        </Text>
        <Text textStyle="h6" fontSize={{base: '19px', xl: '21px'}}>
          {abbreviatedTimeDifference}
        </Text>
      </Flex>
      <PurchaseButtonWithPrice
        color={color}
        price={currentPrice}
        link={url}
        disabled={!piecesAvailable}
        currencySymbol={currencySymbol}
        currencyPostFix={currencyPostFix}
      />
    </Box>
  );
};
