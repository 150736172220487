import {Flex, Box, Button, Text} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import mixpanel from 'mixpanel-browser';
import {fireGtmDataLayerEvent, EventType} from '../../../utils/gtm-data-layer';

interface PurchaseButtonWithPrice {
  color: string;
  price?: string;
  btnLabel?: string;
  priceLabel?: string;
  link?: string;
  disabled?: boolean;
  currencySymbol: string;
  currencyPostFix: string;
}

export const PurchaseButtonWithPrice: React.FC<PurchaseButtonWithPrice> = ({
  color,
  link,
  price,
  disabled = false,
  btnLabel = 'Buy Pieces',
  priceLabel = 'Price Per Piece',
  currencySymbol,
  currencyPostFix,
}) => {
  const router = useRouter();

  return (
    <Flex align="center" justify={price ? 'space-between' : 'center'} px="24px">
      {price ? (
        <Box textAlign="left">
          <Text
            textStyle="h5"
            lineHeight="16.8px"
            fontSize="12px"
            color="darkGrey"
            mb="-4px"
          >
            {priceLabel}
          </Text>
          <Text color={color} textStyle="h5">
            {currencySymbol}
            {price}
            {currencyPostFix && currencyPostFix.length && (
              <Text as="span" fontSize="0.6em">
                {currencyPostFix}
              </Text>
            )}
          </Text>
        </Box>
      ) : null}
      {link && btnLabel && (
        <Button
          colorScheme="aqua"
          disabled={disabled}
          onClick={() => {
            fireGtmDataLayerEvent(EventType.BuyPieces);
            mixpanel.track('Showpiece card buy pieces click', {
              assetUrl: link,
              page: router.pathname,
            });
            if (link) {
              router.push(link);
            }
          }}
        >
          {btnLabel}
        </Button>
      )}
    </Flex>
  );
};
