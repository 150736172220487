import {useEffect, useState} from 'react';

interface TimeDifference {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function calculateTimeDifference(dateDifference: number): TimeDifference {
  let diffInMilliSeconds = Math.abs(dateDifference) / 1000;

  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  const seconds = Math.floor(diffInMilliSeconds);

  return {days, hours, minutes, seconds};
}

function timeDifferenceInMilliseconds(date: Date): number {
  return date.getTimezoneOffset() * 60000;
}

function getUtcDate(date: string) {
  const d = new Date(date);
  const nowUtc = Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
  );
  return new Date(nowUtc);
}

function convertToAbbreviatedString({
  days,
  hours,
  minutes,
  seconds,
}: TimeDifference): any {
  let textDescription = '';
  if (days > 0) {
    textDescription = `${days}days ${hours}hrs`;
  } else if (hours > 0) {
    textDescription = `${hours}hrs ${minutes}m`;
  } else if (minutes > 5) {
    textDescription = `0hrs ${minutes}m`;
  } else if (minutes > 0 || seconds > 0) {
    textDescription = `< ${minutes}min`;
  }
  return textDescription;
}

interface IuseCountdownTimer {
  abbreviatedTimeDifference: string | null;
  timerIsActive: boolean;
  timerIsValid: boolean;
}

export const useCountdownTimer = (
  utcDate: string | undefined,
): IuseCountdownTimer => {
  const [timeDifference, setTimeDifference] = useState<TimeDifference | null>(
    null,
  );
  const [timerIsValid, setTimerIsValid] = useState(false);

  const [abbreviatedTimeDifference, setAbbreviatedTimeDifference] =
    useState<string>('');

  const [timerIsActive, setTimerIsActive] = useState<boolean>(false);

  const calcTime = () => {
    if (!utcDate) {
      setTimerIsActive(false);
      return;
    }

    const now = new Date();
    const future = getUtcDate(utcDate);
    const timestampDifference =
      +future - +now + timeDifferenceInMilliseconds(now);

    if (future) {
      setTimerIsValid(true);
    }

    if (timestampDifference > 0) {
      setTimeDifference(() => calculateTimeDifference(timestampDifference));
      setTimerIsActive(true);
    } else {
      setTimerIsActive(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => calcTime(), []);

  useEffect(() => {
    if (timeDifference) {
      setAbbreviatedTimeDifference(() =>
        convertToAbbreviatedString(timeDifference),
      );
    }
  }, [timeDifference]);

  return {abbreviatedTimeDifference, timerIsActive, timerIsValid};
};
