import {Box, Text} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useGeolocation} from '../../../hooks/geolocation';
import {PurchaseButtonWithPrice} from '../components/purchase-button-with-price';

interface MarketplaceOpenFooterProps {
  color: string;
  url: string;
  piecesAvailable: number;
  currentPrice: string;
  lastSoldPrice: number;
  basePrice: string;
  currencySymbol: string;
  currencyPostFix: string;
}

export const MarketplaceOpenFooter: React.FC<MarketplaceOpenFooterProps> = ({
  color,
  url,
  piecesAvailable,
  currentPrice,
  lastSoldPrice,
  basePrice,
  currencySymbol,
  currencyPostFix,
}) => {
  const [price, setPrice] = useState('0');
  const {canBuyOnTheMarketplace, isLoadingGeolocation} = useGeolocation();

  useEffect(() => {
    setPrice(() => {
      if (currentPrice.length) {
        return currentPrice;
      }

      if (lastSoldPrice) {
        return lastSoldPrice.toFixed(2);
      }

      return basePrice;
    });
  }, [basePrice, currentPrice, lastSoldPrice]);

  return (
    <Box py={6}>
      <Box mb={4} textAlign="center">
        {piecesAvailable ? (
          <Text as="span" textStyle="h6" color="black">
            {piecesAvailable} Pieces
          </Text>
        ) : (
          <Text as="span" textStyle="h6" color="black">
            0 Pieces
          </Text>
        )}
        <Text textStyle="body2" color={color}>
          Available on the marketplace
        </Text>
      </Box>
      <PurchaseButtonWithPrice
        color={color}
        price={price}
        link={url}
        priceLabel={currentPrice.length ? 'Price Per Piece' : 'Last Sold For'}
        disabled={
          !piecesAvailable || (!canBuyOnTheMarketplace && !isLoadingGeolocation)
        }
        currencySymbol={currencySymbol}
        currencyPostFix={currencyPostFix}
      />
    </Box>
  );
};
