import {Box} from '@chakra-ui/react';

interface ProgressBarProps {
  color: string;
  amount: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({color, amount}) => {
  return (
    <Box height="8px" borderRadius="9px" width="100%" bgColor={color}>
      <Box
        height="8px"
        borderRadius="9px"
        width={`${amount}%`}
        bgColor="tone3"
      />
    </Box>
  );
};
