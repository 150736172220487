import {Box} from '@chakra-ui/react';
import {PurchaseButtonWithPrice} from '../components/purchase-button-with-price';
import {OwnersipStats} from '../components/ownership-stats';

interface OfferingOpenFooterProps {
  color: string;
  url: string;
  piecesAvailable: number;
  currentPrice: string;
  piecesSold: number;
  offeringPiecesSold: number;
  offeringPiecesTotal: number;
  currencySymbol: string;
  currencyPostFix: string;
  hidePurchaseInfo?: boolean;
  assetId?: string;
}

export const OfferingOpenFooter: React.FC<OfferingOpenFooterProps> = ({
  color,
  url,
  currentPrice,
  piecesAvailable,
  piecesSold,
  currencySymbol,
  currencyPostFix,
  hidePurchaseInfo,
  assetId,
  offeringPiecesTotal,
  offeringPiecesSold,
}) => {
  return (
    <Box py={6}>
      <OwnersipStats
        barColor={color}
        availablePieces={piecesAvailable}
        totalPiecesOwned={piecesSold}
        assetId={assetId}
        offeringPiecesTotal={offeringPiecesTotal}
        offeringPiecesSold={offeringPiecesSold}
      />
      {!hidePurchaseInfo && (
        <PurchaseButtonWithPrice
          color={color}
          price={currentPrice}
          link={url}
          disabled={!piecesAvailable}
          currencySymbol={currencySymbol}
          currencyPostFix={currencyPostFix}
        />
      )}
    </Box>
  );
};
