// eslint-disable-next-line no-restricted-imports
import {Flex, Box} from '@chakra-ui/react';
import {CardTopBackground} from './card-tops/card-top-background';
import {CardTopCentered} from './card-tops/card-top-centered';
import {CardImage, CardHeadingSizes} from './card-utils';
import {FooterController} from './card-footer/footer-controller';
import {getCmsTheme} from '../../utils/utils';

type Product = {
  slug: string;
};
export interface ShowpieceCardProps {
  assetId: string;
  cardType: string;
  theme: string;
  slug: string;
  product: Product | null;
  cardImage: CardImage;
  cardSubtitle: string;
  cardDescription: string;
  cardTitle: string;
  cardTitleSize: CardHeadingSizes;
  hidePurchaseInfo?: boolean;
}

export const ShowpieceCard: React.FC<ShowpieceCardProps> = ({
  assetId,
  cardType,
  theme,
  cardImage,
  cardTitle,
  cardTitleSize,
  cardSubtitle,
  slug,
  cardDescription,
  product,
  hidePurchaseInfo,
}) => {
  const themeColors = getCmsTheme(theme);
  return (
    <Flex
      bgColor="superlight"
      boxShadow="0px 12px 34px rgba(0, 0, 0, 0.1)"
      borderRadius="24px"
      overflow="hidden"
      height="100%"
      direction="column"
      align="stretch"
      minH="100%"
    >
      {cardType === 'centered' && (
        <CardTopCentered
          acentColour={themeColors?.secondary || ''}
          background={themeColors?.primary || ''}
          image={cardImage}
          title={cardTitle}
          titleSize={cardTitleSize}
          description={cardDescription}
          urlSlug={product ? product.slug : slug}
        />
      )}
      {cardType === 'bg_image' && (
        <CardTopBackground
          acentColour={themeColors?.secondary || ''}
          background={themeColors?.primary || ''}
          image={cardImage}
          title={cardTitle}
          titleSize={cardTitleSize}
          subTitle={cardSubtitle}
          urlSlug={product ? product.slug : slug}
        />
      )}
      {assetId && (
        <Box bg="white">
          <FooterController
            assetID={assetId}
            color={themeColors?.secondary || ''}
            purchaseUrl={`own-a-piece/${slug}`}
            productUrl={product ? product.slug : slug}
            hidePurchaseInfo={hidePurchaseInfo}
          />
        </Box>
      )}
    </Flex>
  );
};
