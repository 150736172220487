// eslint-disable-next-line no-restricted-imports
import {Box, Flex, Text} from '@chakra-ui/react';
import {theme} from '../../../style/theme';
import {ProgressBar} from './progress-bar';

interface OwnersipStatsProps {
  totalPiecesOwned: number;
  availablePieces: number;
  offeringPiecesSold: number;
  offeringPiecesTotal: number;
  barColor: string;
  assetId?: string;
}

export const OwnersipStats: React.FC<OwnersipStatsProps> = ({
  totalPiecesOwned,
  availablePieces,
  barColor,
  assetId,
  offeringPiecesSold,
  offeringPiecesTotal,
}) => {
  const percentageOwned = (offeringPiecesSold / offeringPiecesTotal) * 100;

  const piecesOwned =
    assetId === 'valentines.day.mascara'
      ? totalPiecesOwned - 33000
      : totalPiecesOwned;

  return (
    <Box mb="24px" px="24px">
      <Flex justify="space-between" mb="12px">
        <Box>
          <Text
            textStyle="h7"
            textAlign="left"
            lineHeight="1"
            textTransform="uppercase"
            color={theme.colors.tone3}
          >
            <Text as="span" display="block">
              {piecesOwned}
            </Text>
            <Text as="span" display="block" fontWeight="500" fontSize="12px">
              total pieces owned
            </Text>
          </Text>
        </Box>
        {availablePieces && (
          <Box>
            <Text
              textStyle="h7"
              textAlign="right"
              lineHeight="1"
              textTransform="uppercase"
              color={barColor}
            >
              <Text as="span" display="block">
                {(100 - percentageOwned).toFixed(1)}%
              </Text>
              <Text as="span" display="block" fontWeight="500" fontSize="12px">
                remaining this offering
              </Text>
            </Text>
          </Box>
        )}
      </Flex>
      {availablePieces && (
        <ProgressBar color={barColor} amount={percentageOwned} />
      )}
    </Box>
  );
};
